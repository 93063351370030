
const UNKNOWN = "?";
const DAYS_FULL = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const DAYS_LETTER = DAYS_FULL.map((element) => element.substring(0, 1));
const DAYS_SHORT = DAYS_FULL.map((element) => element.substring(0, 3));

export const DAY_FORMAT = {
    FULL: 'full',
    SHORT: 'short',
    LETTER: 'letter'
}

export const displayRange = (range, format = null) => {
    let formatted = UNKNOWN;
    if (typeof range === 'object') {
        formatted = formatRange(range.start, range.end, format);
    } else if (typeof range === 'string') {
        formatted = formatDay(range, format);
    } else {
        console.error("Wrong type of range. Range type: " + typeof range);
    }

    return formatted;
}

export const formatRange = (start, end, format = null) => {   
    let formatted = UNKNOWN;
    if (start && end) {
        if (start == end) {
            formatted = formatDay(start, format)
        } else {
            const dStart = new Date(start);
            const dEnd = new Date(end);
            if (dStart.getUTCMonth() == dEnd.getUTCMonth()) {
                formatted = `${getDayName(dStart.getUTCDay(), format)}${dStart.getUTCDate()}. - ${getDayName(dEnd.getUTCDay(), format)}${dEnd.getUTCDate()}. ${dEnd.getUTCMonth() + 1}.`;
            } else {
                formatted = `${getDayName(dStart.getUTCDay(), format)}${dStart.getUTCDate()}. ${dStart.getUTCMonth() + 1}. - ${getDayName(dEnd.getUTCDay(), format)}${dEnd.getUTCDate()}. ${dEnd.getUTCMonth() + 1}.`;
            }
        }
    } else {
        formatted = formatDay(start ? start : end);
    }
    return formatted;
}

export const formatDay = (day, format = null) => {
    let formatted = UNKNOWN;
    if (day && typeof day === 'string' && day != UNKNOWN) {
        const dDay = new Date(day);
        formatted = `${getDayName(dDay.getUTCDay(), format)}${dDay.getUTCDate()}. ${dDay.getUTCMonth() + 1}.`;
    }
    
    return formatted;
}

export const getDayName = (day, format = null) => {
    if (format == DAY_FORMAT.FULL) {
        return DAYS_FULL[parseInt(day)] + ' ';
    } else if (format == DAY_FORMAT.SHORT) {
        return DAYS_SHORT[parseInt(day)] + ' ';
    } else if (format == DAY_FORMAT.LETTER) {
        return DAYS_LETTER[parseInt(day)] + ' ';
    } else {
        return '';
    }
}

export const getToday = () => {
    const today = new Date();
    return `${today.getUTCFullYear()}-${makeLength(today.getUTCMonth() + 1, 2, '0')}-${makeLength(today.getUTCDate(), 2, '0')}`;
}

export const makeLength = (value, length, filler) => {
    let filledValue = value.toString();
    for (let i = 0; i < (length - value.toString().length); i++) {
        filledValue = filler + filledValue;
    }
    return filledValue
}