<template>
    <v-app class="no-vertical">
        <v-navigation-drawer app dark permanent :width="180">
            <v-list nav>
                <v-list-item v-for="(menu, i) in navigation"
                    :key="'m_' + i"
                    :active="menu.path === activePath"
                    @click="onMenuClick(menu.path)">
                    <v-list-item-title v-text="menu.label"></v-list-item-title>
                </v-list-item>
                <v-list-item @click="onLogoutClick()">
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
            </v-list>
            <v-system-bar>{{ today }} | {{ user }}</v-system-bar>
        </v-navigation-drawer>
        <v-main>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>

<script>
import initApp from '@/main';
import { getToday, displayRange } from '@/plugins/dates'

export default {
    data: () => ({
        selectedMenuGroup: [false, true],
        navigation: [
            {
                label: "Calendar",
                path: "/calendar"
            },
            {
                label: "Reservations",
                path: '/reservation'
            },
            {
                label: 'Accounts',
                path: '/account'
            },
            {
                label: 'Parking lots',
                path: '/parking'
            }
        ]
    }),

    methods: {
        onMenuClick(path) {
            this.$store.commit('drawer', path)
            this.$router.push(path).catch(() => {})
        },

        onLogoutClick() {
            this.$store.commit('jwt', '')
            this.$store.commit('user', '')
            this.$store.commit('layout', 'blank')
            localStorage.setItem('jwt', '')

            initApp('/')
        }
    },

    computed: {
        today() {
            return displayRange(getToday())
        },
        user() {
            return this.$store.state.user
        },
        activePath() {
            return this.$route.path;
        }
    }
}
</script>

<style>

body::-webkit-scrollbar {
    display: none;
}

</style>